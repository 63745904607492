import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Checkbox,
    FormControlLabel,
    Chip,
    Slide,
} from '@mui/material';
import { setStyles, setAngles, resetParameters, setFormat } from '../../redux/features/images/imagesSlice';
import { RootState } from '../../redux/app/store';
import { Button, makeStyles, shorthands } from '@fluentui/react-components';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Breakpoints } from '../../styles';
import { TransitionProps } from '@mui/material/transitions';
import { angles, anglesLabels, formatLabels, formats, styleImageWithLabels } from '../../Constants';

const ExpandMoreIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99996 10.7071L3.64641 6.35355L4.35352 5.64645L7.99996 9.29289L11.6464 5.64645L12.3535 6.35355L7.99996 10.7071Z"
            fill="#1A1D20"
        />
    </svg>
);

const PlusIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.4962 12.5004V24H12.4962V12.5004H24V11.5004H12.4962V0H11.4962V11.5004H0V12.5004H11.4962Z"
            fill="#030F2B"
        />
    </svg>
);

const MinusIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 12.5L0 12.5L-5.82819e-08 11.5L24 11.5L24 12.5Z"
            fill="#030F2B"
        />
    </svg>
);

const useStyles = makeStyles({
    dialogPaper: {
        position: 'absolute!important' as 'absolute',
        right: 0,
        top: 0,
        ...shorthands.margin('0!important'),
        height: '100%',
        maxHeight: '100%!important',
        maxWidth: '450px!important',
        borderBottomLeftRadius: '24px!important',
        borderTopLeftRadius: '24px!important',
        borderBottomRightRadius: '0!important',
        borderTopRightRadius: '0!important',
        backgroundColor: '#F7F3ED!important',
        ...Breakpoints.small({
            top: '80px',
            height: 'calc(100% - 80px)',
        }),
    },
    accordionTitle: {
        fontSize: '24px',
        fontWeight: '400',
        textTransform: 'uppercase',
        ...shorthands.margin('0'),
    },
    filterQuantity: {
        display: 'inline-block',
        width: '32px',
        height: '32px',
        backgroundColor: '#576DDD',
        ...shorthands.borderRadius('50%'),
        position: 'relative',
        marginLeft: '10px',
        '& span': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '12px',
            color: '#fff',
        },
    },
    filterBtn: {
        textTransform: 'uppercase',
        ...shorthands.borderRadius('32px'),
        ...shorthands.border('1px', 'solid', '#9CB0EC'),
        ...shorthands.padding('16px', '24px'),
        width: '100%',
        color: '#576DDD',
        fontWeight: '400',
        fontSize: '14px',
        ...shorthands.gap('5px'),
        backgroundColor: '#FFFFFF',
        ...shorthands.margin('5px', '0'),
        marginLeft: '0!important',
        '&:hover': {
            ...shorthands.borderColor('#576DDD'),
            color: '#fff',
            backgroundColor: '#576DDD',
            '& svg path': {
                fill: '#fff',
            },
        },
        '&:hover:active': {
            ...shorthands.borderColor('#576DDD'),
            color: '#fff',
            backgroundColor: '#576DDD',
            '& svg path': {
                fill: '#fff',
            },
        },
    },
});

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    () => ({
        border: `0`,
        background: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&::before': {
            display: 'none',
        },
    }),
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 700,
    padding: 0,
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: 0,
    },
    ...theme.applyStyles('dark', {
        backgroundColor: 'rgba(255, 255, 255, .05)',
    }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
    padding: 0,
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const FilterDialog = ({ open, handleClose }: { open: boolean; handleClose: () => void }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { style: selectedStylesFromRedux, angle: selectedAnglesFromRedux } = useSelector(
        (state: RootState) => state.images.options,
    );
    const { model_options } = useSelector((state: RootState) => state.images);
    const [selectedFormat, setSelectedFormat] = useState(model_options.aspect_ratio || '');
    const [localStyle, setLocalStyle] = useState<string | null>(selectedStylesFromRedux[0] || null);
    const [localAngle, setLocalAngle] = useState<string | null>(selectedAnglesFromRedux[0] || null);
    const [expanded, setExpanded] = useState<string[]>([]);

    const handleAccordionChange = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded((prev) =>
            isExpanded ? [...prev, panel] : prev.filter((expandedPanel) => expandedPanel !== panel),
        );
    };

    useEffect(() => {
        if (open) {
            setSelectedFormat(model_options.aspect_ratio || '');
            setLocalStyle(selectedStylesFromRedux[0] || null);
            setLocalAngle(selectedAnglesFromRedux[0] || null);
        }
    }, [open]);

    const handleApplyFilters = () => {
        dispatch(resetParameters());
        dispatch(setFormat(selectedFormat));
        dispatch(setStyles(localStyle ? [localStyle] : []));
        dispatch(setAngles(localAngle ? [localAngle] : []));
        handleClose();
    };

    const handleClearFilters = () => {
        setSelectedFormat('1:1');
        setLocalStyle(null);
        setLocalAngle(null);
        handleClose();
    };

    const handleFormatClick = (format: string) => {
        setSelectedFormat(format);
    };

    const handleStyleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (localStyle === value) {
            setLocalStyle(null);
        } else {
            setLocalStyle(value);
        }
    };

    const handleAngleClick = (angle: string) => {
        setLocalAngle((prev) => (prev === angle ? null : angle));
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            classes={{ paper: classes.dialogPaper }}
            TransitionComponent={Transition}
        >
            <DialogTitle style={{ display: 'flex' }}>
                <div>Filters</div>
                <div className={classes.filterQuantity}>
                    <span>{(localAngle ? 1 : 0) + (localStyle ? 1 : 0)}</span>
                </div>
            </DialogTitle>
            <div
                onClick={handleClose}
                style={{
                    textDecoration: 'underline',
                    textUnderlineOffset: '7px',
                    cursor: 'pointer',
                    position: 'absolute',
                    right: 20,
                    top: 15,
                    color: '#000',
                }}
            >
                CLOSE <span style={{ fontSize: '24px' }}>&times;</span>
            </div>
            <DialogContent>
                <Accordion expanded={expanded.includes('format')} onChange={handleAccordionChange('format')}>
                    <AccordionSummary expandIcon={expanded.includes('format') ? <MinusIcon /> : <PlusIcon />}>
                        <p className={classes.accordionTitle}>Format</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                            {formats.map((format, index) => (
                                <Chip
                                    key={index}
                                    label={formatLabels[format]}
                                    onClick={() => {
                                        handleFormatClick(format);
                                    }}
                                    style={{
                                        boxShadow:
                                            selectedFormat === format ? 'rgba(0, 0, 0, 0.5) 0px 1px 5px 0px' : 'none',
                                        backgroundColor: selectedFormat === format ? '#e0e0e0' : '#F4F5F5',
                                        borderRadius: '4px',
                                        borderColor: '#9CB0EC',
                                        borderStyle: 'solid',
                                        borderWidth: '1px',
                                    }}
                                />
                            ))}
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded.includes('styles')} onChange={handleAccordionChange('styles')}>
                    <AccordionSummary expandIcon={expanded.includes('styles') ? <MinusIcon /> : <PlusIcon />}>
                        <p className={classes.accordionTitle}>Styles</p>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}>
                        {Object.entries(styleImageWithLabels).map(([style, { image, label }], index) => (
                            <FormControlLabel
                                key={index}
                                style={{ position: 'relative', margin: 0 }}
                                control={
                                    <Checkbox
                                        checked={localStyle === style}
                                        onChange={handleStyleChange}
                                        value={style}
                                        sx={{
                                            display: 'none',
                                        }}
                                    />
                                }
                                label={
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <img
                                            src={image}
                                            alt={label}
                                            style={{
                                                width: 120,
                                                height: 120,
                                                borderRadius: 8,
                                                boxShadow:
                                                    style !== localStyle
                                                        ? 'none'
                                                        : 'rgba(0, 0, 0, 0.5) 0px 1px 10px 5px',
                                            }}
                                        />
                                        <div
                                            style={{
                                                fontSize: '16px',
                                                color: '#1A1D20',
                                                fontWeight: '300',
                                                marginTop: '5px',
                                                textShadow:
                                                    style !== localStyle ? 'none' : '0px 1px 3px rgba(0, 0, 0, 0.75)',
                                            }}
                                        >
                                            {label}
                                        </div>
                                    </div>
                                }
                            />
                        ))}
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded.includes('angles')} onChange={handleAccordionChange('angles')}>
                    <AccordionSummary expandIcon={expanded.includes('angles') ? <MinusIcon /> : <PlusIcon />}>
                        <p className={classes.accordionTitle}>Angles</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                            {angles.map((angle, index) => (
                                <Chip
                                    key={index}
                                    label={anglesLabels[angle]}
                                    onClick={() => {
                                        handleAngleClick(angle);
                                    }}
                                    style={{
                                        boxShadow: localAngle === angle ? 'rgba(0, 0, 0, 0.5) 0px 1px 5px 0px' : 'none',
                                        backgroundColor: localAngle === angle ? '#e0e0e0' : '#F4F5F5',
                                        borderRadius: '4px',
                                        borderColor: '#9CB0EC',
                                        borderStyle: 'solid',
                                        borderWidth: '1px',
                                    }}
                                />
                            ))}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </DialogContent>
            <DialogActions style={{ borderTop: '1px solid #E0E5F0', flexDirection: 'column', padding: '20px 24px' }}>
                <Button className={classes.filterBtn} onClick={handleClearFilters}>
                    Cancel
                </Button>
                <Button className={classes.filterBtn} onClick={handleApplyFilters}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FilterDialog;
