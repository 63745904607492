import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, shorthands, Spinner, tokens } from '@fluentui/react-components';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { IGalleryItems } from '../../libs/services/ImagesService';
import { useImages } from '../../libs/hooks/useImages';
import {
    addToGallery,
    setAngles,
    setGallery,
    setModelOptions,
    setPrompt,
    setStyles,
    updateFavorite,
    updateFeedback,
} from '../../redux/features/images/imagesSlice';
import { Dialog, DialogSurface, Image } from '@fluentui/react-components';
import { setSelectedTab } from '../../redux/features/app/appSlice';
import { RootState } from '../../redux/app/store';
import { Breakpoints } from '../../styles';
import { useSwipeable } from 'react-swipeable';
import { IconButton } from '@mui/material';
import moment from 'moment';

const ArrowLeftIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.6465 22.3535L6.29292 12L16.6465 1.64641L17.3536 2.35352L7.70713 12L17.3536 21.6464L16.6465 22.3535Z"
            fill="white"
        />
    </svg>
);

const ArrowRightIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.35355 1.64648L17.7071 12L7.35355 22.3536L6.64645 21.6465L16.2929 12L6.64645 2.35359L7.35355 1.64648Z"
            fill="white"
        />
    </svg>
);

const ThumbsUpIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.0346 5.78379L10.2602 1.67516C10.2878 1.68172 10.3054 1.69092 10.3167 1.69859C10.3394 1.714 10.3708 1.74648 10.3986 1.82473C10.4603 1.99818 10.4696 2.29797 10.4085 2.73262C10.2955 3.5369 9.97421 4.60612 9.64314 5.70801L9.58088 5.91533C9.5506 6.01626 9.56982 6.12556 9.63272 6.21009C9.69562 6.29463 9.79478 6.34445 9.90015 6.34445H13.595L13.5971 6.34444C14.0059 6.34192 14.455 6.71528 14.7476 7.33482C15.036 7.94542 15.0801 8.6101 14.8645 9.00099L12.5283 12.9695C12.5254 12.9744 12.5226 12.9795 12.52 12.9845C12.3971 13.2203 12.2528 13.4049 12.0682 13.532C11.8872 13.6566 11.6441 13.7421 11.2942 13.7421H6.90227C5.96482 13.7421 5.31696 13.0552 5.31696 12.3217V7.08286C5.31696 6.62624 5.58044 6.23825 6.0346 5.78379ZM11.0687 2.82543C10.9556 3.62964 10.6534 4.66162 10.3484 5.67778H13.5941L13.593 5.67779L13.595 6.01112V5.67778H13.5941C14.3989 5.67344 15.0211 6.35278 15.3504 7.05013C15.6833 7.75507 15.8122 8.66983 15.4451 9.32862L15.4413 9.33554L15.4412 9.33549L13.1071 13.3005C12.9531 13.594 12.7461 13.8747 12.4463 14.0811C12.1401 14.2919 11.7611 14.4088 11.2942 14.4088H6.90227C5.63627 14.4088 4.65029 13.4618 4.65029 12.3217V7.08286C4.65029 6.34088 5.09067 5.78492 5.56484 5.31074L5.56815 5.30743L5.56817 5.30746L9.90012 1.09543C9.95832 1.03884 10.0352 1.00544 10.1162 1.00148C10.3242 0.991338 10.5227 1.03261 10.6913 1.14714C10.8593 1.2613 10.9644 1.42568 11.0268 1.60154C11.1458 1.93633 11.1322 2.37383 11.0687 2.82543ZM0.99998 7.59992C0.99998 7.35767 1.19636 7.1613 1.4386 7.1613H2.69771C2.93996 7.1613 3.13633 7.35767 3.13633 7.59992V13.3034C3.13633 13.5456 2.93996 13.742 2.69771 13.742H1.4386C1.19636 13.742 0.99998 13.5456 0.99998 13.3034V7.59992ZM1.4386 6.49463C0.828167 6.49463 0.333313 6.98948 0.333313 7.59992V13.3034C0.333313 13.9138 0.828167 14.4087 1.4386 14.4087H2.69771C3.30815 14.4087 3.803 13.9138 3.803 13.3034V7.59992C3.803 6.98948 3.30815 6.49463 2.69771 6.49463H1.4386Z"
            fill="#576DDD"
        />
    </svg>
);

const ThumbsDownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.004 2.65944H2.34267C2.5275 2.65944 2.67733 2.80707 2.67733 2.98917V8.92417C2.67733 9.10627 2.5275 9.25389 2.34267 9.25389H1.004C0.819169 9.25389 0.669333 9.10627 0.669333 8.92417V2.98917C0.669333 2.80707 0.819169 2.65944 1.004 2.65944ZM2.34267 2H1.004C0.449506 2 0 2.44286 0 2.98917V8.92417C0 9.47047 0.449506 9.91333 1.004 9.91333H2.34267C2.89716 9.91333 3.34667 9.47047 3.34667 8.92417V2.98917C3.34667 2.44286 2.89716 2 2.34267 2ZM6.37965 2C5.26423 2 4.36 2.90716 4.36 4.0262V9.14151C4.36 9.96448 4.69266 10.7523 5.28193 11.3248L9.21757 15.1486C9.79302 15.7077 10.7556 15.2986 10.7556 14.4949L10.7528 14.4519L10.6916 13.9738C10.607 13.3128 10.4634 12.6608 10.2626 12.0256L9.86899 10.7802H13.2983C13.6992 10.7802 14.0837 10.6204 14.3672 10.336C14.8498 9.85191 15.1695 9.22864 15.2817 8.55332L15.3413 8.19459C15.4333 7.64043 15.3257 7.07149 15.0375 6.58975L12.7829 2.81977C12.4787 2.31119 11.9309 2 11.3397 2H6.37965ZM10.0239 14.0599L10.0818 14.512C10.0686 14.7078 9.8301 14.8035 9.68591 14.6634L5.75028 10.8396C5.29195 10.3943 5.03321 9.7816 5.03321 9.14151V4.0262C5.03321 3.28018 5.63603 2.6754 6.37965 2.6754H11.3397C11.6944 2.6754 12.0231 2.86211 12.2056 3.16726L14.4603 6.93724C14.6661 7.28134 14.743 7.68772 14.6772 8.08355L14.6176 8.44229C14.5285 8.97875 14.2745 9.47387 13.8912 9.85843C13.734 10.0162 13.5207 10.1048 13.2983 10.1048H9.63906C9.29754 10.1048 9.05454 10.4379 9.15776 10.7645L9.62088 12.2297C9.80948 12.8265 9.94436 13.439 10.0239 14.0599Z"
            fill="#576DDD"
        />
    </svg>
);

const ThumbsDownIconFilled = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <g>
                <path
                    d="M9 2L11 3.5L11 9L5.5 14.5L5 13.5L6 10L2 10L0.5 7.5L3.5 2L9 2ZM14.5 8.30469C14.5 8.45004 14.3822 8.56787 14.2368 8.56787H12.992C12.8467 8.56787 12.7288 8.45004 12.7288 8.30469L12.7288 2.66603C12.7288 2.52067 12.8467 2.40284 12.992 2.40284H14.2368C14.3822 2.40284 14.5 2.52067 14.5 2.66603L14.5 8.30469ZM14.2368 9.56787C14.9345 9.56787 15.5 9.00233 15.5 8.30469L15.5 2.66603C15.5 1.96839 14.9345 1.40284 14.2368 1.40284H12.992C12.2944 1.40284 11.7288 1.96839 11.7288 2.66603L11.7288 8.30469C11.7288 9.00233 12.2944 9.56787 12.992 9.56787H14.2368Z"
                    fill="#576DDD"
                ></path>
                <path
                    d="M5.53758 13.1404C5.48379 13.523 5.49063 13.7727 5.52622 13.9138L9.57331 9.97877C10.0168 9.53483 10.2321 9.19427 10.2321 8.81581L10.2321 3.63654C10.2321 3.01532 9.67801 2.40271 8.83521 2.40271L4.49322 2.40271C4.17713 2.40271 3.97155 2.47922 3.82463 2.58036C3.67227 2.68526 3.54586 2.84242 3.43249 3.05997C3.42851 3.06761 3.42434 3.07514 3.41997 3.08256L1.11163 7.00378C0.937449 7.32229 0.959777 7.91505 1.23316 8.49393C1.51298 9.08646 1.91258 9.37726 2.21541 9.37539L2.21849 9.37538L5.8714 9.37538C6.02945 9.37538 6.1782 9.45011 6.27254 9.57691C6.36689 9.70372 6.39573 9.86767 6.35031 10.0191L6.29066 10.2177L6.29065 10.2177C5.9615 11.3134 5.64748 12.3587 5.53758 13.1404ZM4.54732 13.0011C4.65276 12.2512 4.9208 11.3082 5.19929 10.3754L2.21989 10.3754L2.22158 10.3754L2.21849 9.87538V10.3754H2.21989C1.32323 10.38 0.663878 9.63023 0.328923 8.92097C-0.011404 8.20033 -0.160574 7.23253 0.240523 6.51261L0.246303 6.50224L0.246424 6.50231L2.55192 2.58592C2.71219 2.28144 2.93254 1.98046 3.25756 1.75669C3.59226 1.52626 4.00167 1.40271 4.49322 1.40271L8.83521 1.40271C10.1708 1.40271 11.2321 2.40538 11.2321 3.63654L11.2321 8.81581C11.2321 9.62219 10.7516 10.2147 10.278 10.6883L10.273 10.6933L10.273 10.6933L5.99026 14.8574C5.90296 14.9423 5.78768 14.9924 5.66606 14.9983C5.43277 15.0097 5.19755 14.9637 4.99345 14.8251C4.79015 14.687 4.66753 14.4907 4.59689 14.2919C4.46464 13.9197 4.48394 13.4519 4.54732 13.0011ZM12.5 2H15L15 9H12.5L12.5 2Z"
                    fill="#576DDD"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1631_4958">
                    <rect width="16" height="16" fill="white" transform="matrix(-1 0 0 -1 16 16.5)"></rect>
                </clipPath>
            </defs>
        </svg>
    );
};

const ThumbsUpIconFilled = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            style={{ transform: 'rotate(180deg)' }}
        >
            <g>
                <path
                    d="M9 2L11 3.5L11 9L5.5 14.5L5 13.5L6 10L2 10L0.5 7.5L3.5 2L9 2ZM14.5 8.30469C14.5 8.45004 14.3822 8.56787 14.2368 8.56787H12.992C12.8467 8.56787 12.7288 8.45004 12.7288 8.30469L12.7288 2.66603C12.7288 2.52067 12.8467 2.40284 12.992 2.40284H14.2368C14.3822 2.40284 14.5 2.52067 14.5 2.66603L14.5 8.30469ZM14.2368 9.56787C14.9345 9.56787 15.5 9.00233 15.5 8.30469L15.5 2.66603C15.5 1.96839 14.9345 1.40284 14.2368 1.40284H12.992C12.2944 1.40284 11.7288 1.96839 11.7288 2.66603L11.7288 8.30469C11.7288 9.00233 12.2944 9.56787 12.992 9.56787H14.2368Z"
                    fill="#576DDD"
                ></path>
                <path
                    d="M5.53758 13.1404C5.48379 13.523 5.49063 13.7727 5.52622 13.9138L9.57331 9.97877C10.0168 9.53483 10.2321 9.19427 10.2321 8.81581L10.2321 3.63654C10.2321 3.01532 9.67801 2.40271 8.83521 2.40271L4.49322 2.40271C4.17713 2.40271 3.97155 2.47922 3.82463 2.58036C3.67227 2.68526 3.54586 2.84242 3.43249 3.05997C3.42851 3.06761 3.42434 3.07514 3.41997 3.08256L1.11163 7.00378C0.937449 7.32229 0.959777 7.91505 1.23316 8.49393C1.51298 9.08646 1.91258 9.37726 2.21541 9.37539L2.21849 9.37538L5.8714 9.37538C6.02945 9.37538 6.1782 9.45011 6.27254 9.57691C6.36689 9.70372 6.39573 9.86767 6.35031 10.0191L6.29066 10.2177L6.29065 10.2177C5.9615 11.3134 5.64748 12.3587 5.53758 13.1404ZM4.54732 13.0011C4.65276 12.2512 4.9208 11.3082 5.19929 10.3754L2.21989 10.3754L2.22158 10.3754L2.21849 9.87538V10.3754H2.21989C1.32323 10.38 0.663878 9.63023 0.328923 8.92097C-0.011404 8.20033 -0.160574 7.23253 0.240523 6.51261L0.246303 6.50224L0.246424 6.50231L2.55192 2.58592C2.71219 2.28144 2.93254 1.98046 3.25756 1.75669C3.59226 1.52626 4.00167 1.40271 4.49322 1.40271L8.83521 1.40271C10.1708 1.40271 11.2321 2.40538 11.2321 3.63654L11.2321 8.81581C11.2321 9.62219 10.7516 10.2147 10.278 10.6883L10.273 10.6933L10.273 10.6933L5.99026 14.8574C5.90296 14.9423 5.78768 14.9924 5.66606 14.9983C5.43277 15.0097 5.19755 14.9637 4.99345 14.8251C4.79015 14.687 4.66753 14.4907 4.59689 14.2919C4.46464 13.9197 4.48394 13.4519 4.54732 13.0011ZM12.5 2H15L15 9H12.5L12.5 2Z"
                    fill="#576DDD"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1631_4958">
                    <rect width="16" height="16" fill="white" transform="matrix(-1 0 0 -1 16 16.5)"></rect>
                </clipPath>
            </defs>
        </svg>
    );
};

const StarIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <g id="Icon Right">
            <path
                id="Vector"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.49965 0.833008L10.4916 5.75857L15.7917 6.13096L11.7227 9.54752L13.0064 14.7032L8.49965 11.8892L3.99294 14.7032L5.27658 9.54752L1.20764 6.13096L6.50768 5.75857L8.49965 0.833008ZM7.20064 6.71235L3.74437 6.95519L6.39781 9.18321L5.56072 12.5454L8.49965 10.7103L11.4386 12.5454L10.6015 9.18321L13.2549 6.95519L9.79865 6.71235L8.49965 3.50028L7.20064 6.71235Z"
                fill="#576DDD"
            />
        </g>
    </svg>
);

const StarActiveIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <g id="Icon/Favorite">
            <path
                id="Fill"
                d="M8.50003 3.5L9.79904 6.71207L13.2553 6.95492L10.6019 9.18293L11.439 12.5451L8.50003 10.71L5.56111 12.5451L6.3982 9.18293L3.74475 6.95492L7.20103 6.71207L8.50003 3.5Z"
                fill="#576DDD"
            />
            <path
                id="Vector"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.50001 0.833008L10.492 5.75857L15.792 6.13096L11.7231 9.54752L13.0067 14.7032L8.50001 11.8892L3.99331 14.7032L5.27695 9.54751L1.20801 6.13096L6.50805 5.75857L8.50001 0.833008ZM7.20101 6.71235L3.74473 6.95519L6.39818 9.1832L5.56109 12.5454L8.50001 10.7103L11.4389 12.5454L10.6018 9.1832L13.2553 6.95519L9.79902 6.71235L8.50001 3.50028L7.20101 6.71235Z"
                fill="#576DDD"
            />
        </g>
    </svg>
);

const ReloadIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.2933 7.33333H13.96C13.6333 4.34 11.08 2 8 2C5.68 2 3.66 3.32667 2.66667 5.26V2.66667H2V6.66667H6V6H3.06C3.84667 4.04667 5.76667 2.66667 8 2.66667C10.7133 2.66667 12.9667 4.70667 13.2933 7.33333ZM14 13.3333V9.33333H10V10H12.94C12.1534 11.9533 10.2334 13.3333 8.00004 13.3333C5.28671 13.3333 3.03337 11.2933 2.70671 8.66667H2.04004C2.36671 11.66 4.92004 14 8.00004 14C10.32 14 12.3334 12.6733 13.3334 10.74V13.3333H14Z"
            fill="#576DDD"
        />
    </svg>
);

const UploadIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.49999 10.1262V2H8.49999V10.1262L11.6464 6.97978L12.3535 7.68689L7.99999 12.0404L3.64644 7.68689L4.35355 6.97978L7.49999 10.1262ZM12.6667 13.5V14.5H3.33333V13.5H12.6667Z"
            fill="#576DDD"
        />
    </svg>
);

const CloseIcon = () => (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="56" height="56" fill="white" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28 28.7071L37.6464 38.3536L38.3535 37.6465L28.7071 28L38.3535 18.3536L37.6464 17.6465L28 27.2929L18.3535 17.6465L17.6464 18.3536L27.2929 28L17.6464 37.6465L18.3535 38.3536L28 28.7071Z"
            fill="#030F2B"
        />
    </svg>
);

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow('auto'),
        ...shorthands.padding(tokens.spacingVerticalM),
        marginLeft: '40px',
        paddingRight: '40px',
        paddingBottom: '40px',
        display: 'flex',
        justifyContent: 'center',
        ...Breakpoints.small({
            marginLeft: '15px',
            paddingRight: '15px',
        }),
    },
    wrapper: {
        ...shorthands.gap(tokens.spacingVerticalM),
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '900px',
        width: '100%',
        justifySelf: 'center',
    },
    sectionTitle: {
        ...shorthands.margin(0),
        marginBottom: '16px',
        fontSize: '16px',
        fontWeight: '400',
    },
    gridContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        ...shorthands.gap('8px'),
        justifyItems: 'center',
    },
    gridItem: {
        width: '120px',
        height: '120px',
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        cursor: 'pointer',
        boxShadow: tokens.shadow8,
        ...shorthands.borderRadius('8px'),
        ':hover': {
            transform: 'scale(1.05)',
        },
    },
    previewImage: {
        position: 'absolute',
        width: '60%',
        height: 'auto',
        maxWidth: '60%',
        ...shorthands.borderRadius('16px'),
        zIndex: 1,
        pointerEvents: 'none',
        display: 'none',
        ...Breakpoints.small({
            display: 'block',
        }),
    },
    img: {
        ...shorthands.outline('none'),
    },
    dialog: {
        backgroundColor: 'transparent',
        ...shorthands.padding('0'),
        ...shorthands.border('0'),
        boxShadow: 'none!important',
        maxWidth: '600px',
        overflowY: 'auto',
        ...Breakpoints.laptop({
            maxWidth: '500px',
        }),
    },
    iconClose: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed!important' as 'fixed',
        top: '0',
        right: '0',
        zIndex: '10000',
        ...shorthands.padding('30px'),
        '&:hover': {
            backgroundColor: 'transparent!important',
        },
        '& .MuiTouchRipple-root': {
            display: 'none',
        },
        ...Breakpoints.small({
            maxWidth: '500px',
        }),
    },
});

const formatDateWithOrdinal = (dateString: string): string => {
    const date = moment.utc(dateString);

    if (!date.isValid()) {
        console.error(`Invalid date string: ${dateString}`);
        return 'Invalid date';
    }

    const day = date.date();

    const ordinal = (n: number): string => {
        if (n > 3 && n < 21) return 'th';
        switch (n % 10) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    };

    const month = date.format('MMMM');
    const year = date.year();

    return `${month} ${day.toString()}${ordinal(day)}, ${year.toString()}`;
};

interface GalleryProps {
    isFavorite?: boolean;
}

export const Gallery: React.FC<GalleryProps> = ({ isFavorite = false }) => {
    const scrollableRef = useRef<HTMLDivElement>(null);
    const gallery = useAppSelector((state: RootState) => state.images.gallery);
    const images = useImages();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState('');
    const [downloadImageUrl, setDownloadImageUrl] = useState('');
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [imageTransitioning, setImageTransitioning] = useState(false);
    const [isExiting, setIsExiting] = useState(false);
    const [slideDirection, setSlideDirection] = useState<'left' | 'right'>('right');
    const [favoriteLoading, setFavoriteLoading] = useState(false);
    const [feedbackLoading, setFeedbackLoading] = useState(false);
    const classes = useStyles();

    const handleNextImage = () => {
        if (currentImageIndex < gallery.items.length - 1) {
            setSlideDirection('left');
            setIsExiting(true);
            setImageTransitioning(true);
            setTimeout(() => {
                setCurrentImageIndex(currentImageIndex + 1);
                setImage(gallery.items[currentImageIndex + 1].thumbnail_url);
                setDownloadImageUrl(gallery.items[currentImageIndex + 1].download_url);
                setIsExiting(false);
                setImageTransitioning(false);
            }, 100);
        }
    };

    const handlePreviousImage = () => {
        if (currentImageIndex > 0) {
            setSlideDirection('right');
            setIsExiting(true);
            setImageTransitioning(true);
            setTimeout(() => {
                setCurrentImageIndex(currentImageIndex - 1);
                setImage(gallery.items[currentImageIndex - 1].thumbnail_url);
                setDownloadImageUrl(gallery.items[currentImageIndex - 1].download_url);
                setIsExiting(false);
                setImageTransitioning(false);
            }, 100);
        }
    };

    const handleImage = (value: string, downloadUrl: string, index: number) => {
        setDownloadImageUrl(downloadUrl);
        setImage(value);
        setCurrentImageIndex(index);
        setOpen(true);
    };

    const fetchGallery = async (page: number) => {
        setLoading(true);
        try {
            const response = await images.fetchGallery(page.toString(), gallery.size?.toString() ?? '100', isFavorite);
            if (page === 1) {
                dispatch(setGallery(response));
            } else {
                dispatch(addToGallery(response));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        void fetchGallery(1);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const container = scrollableRef.current;

            if (container) {
                const bottom = Math.ceil(container.scrollTop + container.clientHeight) >= container.scrollHeight;

                if (bottom && !loading && gallery.page < (gallery.pages ?? 0)) {
                    void fetchGallery(gallery.page + 1);
                }
            }
        };

        const container = scrollableRef.current;
        container?.addEventListener('scroll', handleScroll);

        return () => {
            container?.removeEventListener('scroll', handleScroll);
        };
    }, [loading, gallery.page, gallery.pages]);

    const groupedByDate = gallery.items.reduce(
        (acc, item, originalIndex) => {
            const date = moment.utc(item.date).isValid() ? moment.utc(item.date).format('YYYY-MM-DD') : 'Invalid Date';

            if (!acc[date]) {
                acc[date] = [];
            }

            acc[date].push({ ...item, originalIndex });

            return acc;
        },
        // eslint-disable-next-line
        {} as Record<string, Array<IGalleryItems & { originalIndex: number }>>,
    );

    const handleFavoriteToggle = async () => {
        const currentFavoriteStatus = gallery.items[currentImageIndex].favorite;
        try {
            setFavoriteLoading(true);

            await images.setImageFavorite(
                gallery.items[currentImageIndex].prompt_uuid,
                gallery.items[currentImageIndex].image_uuid,
                !currentFavoriteStatus,
            );

            dispatch(updateFavorite({ index: currentImageIndex, favorite: !currentFavoriteStatus }));
        } catch (error) {
            console.error('Error updating favorite status:', error);
        } finally {
            setFavoriteLoading(false);
        }
    };

    const handleFeedbackToggle = async (feedbackValue: 'positive' | 'negative' | null) => {
        try {
            setFeedbackLoading(true);

            await images.setImageFeedback(
                gallery.items[currentImageIndex].prompt_uuid,
                gallery.items[currentImageIndex].image_uuid,
                feedbackValue,
            );

            dispatch(updateFeedback({ index: currentImageIndex, feedback: feedbackValue }));
        } catch (error) {
            console.error('Error updating feedback status:', error);
        } finally {
            setFeedbackLoading(false);
        }
    };

    const handleFetchPromptByUuid = async (promptUuid: string) => {
        try {
            // setFeedbackLoading(true);

            const response = await images.fetchPromptByUuid(promptUuid);
            dispatch(setPrompt(response?.prompt ?? ''));
            dispatch(setStyles(response?.style ?? []));
            dispatch(setAngles(response?.angle ?? []));
            dispatch(
                setModelOptions({
                    aspect_ratio: response?.aspect_ratio,
                    model: response?.model,
                }),
            );
            dispatch(setSelectedTab('images'));

            // dispatch(updateFeedback({ index: currentImageIndex, feedback: feedbackValue }));
        } catch (error) {
            console.error('Error updating feedback status:', error);
        } finally {
            // setFeedbackLoading(false);
        }
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleNextImage,
        onSwipedRight: handlePreviousImage,
        preventScrollOnSwipe: true,
        trackMouse: true,
    });

    return (
        <>
            <Dialog
                open={open}
                onOpenChange={(_, data) => {
                    setOpen(data.open);
                }}
            >
                <>
                    {open ? (
                        <>
                            <IconButton
                                className={classes.iconClose}
                                aria-label="close"
                                onClick={() => {
                                    //eslint-disable-next-line
                                    setOpen(false);
                                }}
                            >
                                <span style={{ fontSize: '20px', color: '#fff', marginRight: '24px' }}>Close</span>
                                <CloseIcon />
                            </IconButton>
                        </>
                    ) : (
                        ''
                    )}
                </>
                <DialogSurface className={classes.dialog}>
                    <div {...swipeHandlers} style={{ display: 'flex', alignItems: 'center' }}>
                        <button
                            style={{
                                padding: '10px',
                                cursor: currentImageIndex === 0 ? 'not-allowed' : 'pointer',
                                fontSize: '24px',
                                color: '#fff',
                                zIndex: 3,
                            }}
                            onClick={handlePreviousImage}
                            disabled={currentImageIndex === 0}
                        >
                            <ArrowLeftIcon />
                        </button>
                        <div className="modalContainer">
                            <div>
                                <a
                                    className="downloadButton"
                                    href={downloadImageUrl}
                                    style={{ cursor: 'pointer', textDecoration: 'none' }}
                                >
                                    <UploadIcon />
                                    <span>Download</span>
                                </a>
                                <button
                                    className="favoriteButton"
                                    onClick={() => {
                                        void handleFavoriteToggle();
                                    }}
                                    style={{
                                        cursor: favoriteLoading ? 'not-allowed' : 'pointer',
                                    }}
                                    disabled={favoriteLoading}
                                >
                                    <div
                                        className={`starIcon ${gallery.items[currentImageIndex]?.favorite ? 'selected' : ''}`}
                                    >
                                        {gallery.items[currentImageIndex]?.favorite ? <StarActiveIcon /> : <StarIcon />}
                                    </div>
                                    <span>Favorite</span>
                                </button>
                                <div
                                    className="evaluateButton"
                                    style={{
                                        pointerEvents: feedbackLoading ? 'none' : 'auto',
                                    }}
                                >
                                    <span>Evaluate</span>
                                    <div
                                        onClick={() => {
                                            !feedbackLoading &&
                                                (gallery.items[currentImageIndex]?.feedback === 'negative' ||
                                                    gallery.items[currentImageIndex]?.feedback === null) &&
                                                void handleFeedbackToggle('positive');
                                        }}
                                        className={`thumbsIcon ${feedbackLoading ? 'disabled' : ''} ${gallery.items[currentImageIndex]?.feedback === 'positive' ? 'selected' : ''}`}
                                        style={{
                                            cursor: feedbackLoading ? 'not-allowed' : 'pointer',
                                            textDecoration: 'underline',
                                            margin: '0 1px',
                                        }}
                                    >
                                        {gallery.items[currentImageIndex]?.feedback === 'positive' ? (
                                            <ThumbsUpIconFilled />
                                        ) : (
                                            <ThumbsUpIcon />
                                        )}
                                    </div>
                                    <div
                                        onClick={() => {
                                            !feedbackLoading &&
                                                (gallery.items[currentImageIndex]?.feedback === 'positive' ||
                                                    gallery.items[currentImageIndex]?.feedback === null) &&
                                                void handleFeedbackToggle('negative');
                                        }}
                                        className={`thumbsIcon ${feedbackLoading ? 'disabled' : ''} ${gallery.items[currentImageIndex]?.feedback === 'negative' ? 'selected' : ''}`}
                                        style={{
                                            cursor: feedbackLoading ? 'not-allowed' : 'pointer',
                                            textDecoration: 'underline',
                                            margin: '0 1px',
                                        }}
                                    >
                                        {gallery.items[currentImageIndex]?.feedback === 'negative' ? (
                                            <ThumbsDownIconFilled />
                                        ) : (
                                            <ThumbsDownIcon />
                                        )}
                                    </div>
                                </div>
                                <a
                                    className="reusePromptButton"
                                    onClick={() => {
                                        void handleFetchPromptByUuid(
                                            gallery.items[currentImageIndex]?.prompt_uuid || '',
                                        );
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <ReloadIcon />
                                    <span>Regenerate</span>
                                </a>
                            </div>
                            {currentImageIndex > 0 && (
                                <img
                                    src={gallery.items[currentImageIndex - 1].image_url}
                                    alt="Previous image"
                                    className={classes.previewImage}
                                    style={{ left: '-40%', opacity: 0.9 }}
                                />
                            )}
                            <Image
                                src={image}
                                alt="carousel image"
                                className={`carousel-image ${
                                    imageTransitioning
                                        ? isExiting
                                            ? slideDirection === 'left'
                                                ? 'slide-exit-left'
                                                : 'slide-exit-right'
                                            : slideDirection === 'left'
                                              ? 'slide-enter-left'
                                              : 'slide-enter-right'
                                        : ''
                                }`}
                                style={{ maxHeight: '90%', maxWidth: '90%', borderRadius: '16px', zIndex: '2' }}
                                shadow={true}
                            />
                            {currentImageIndex < gallery.items.length - 1 && (
                                <img
                                    src={gallery.items[currentImageIndex + 1].image_url}
                                    alt="Next image"
                                    className={classes.previewImage}
                                    style={{ right: '-40%', opacity: 0.9 }}
                                />
                            )}
                        </div>
                        <button
                            style={{
                                padding: '10px',
                                cursor: currentImageIndex === gallery.items.length - 1 ? 'not-allowed' : 'pointer',
                                fontSize: '24px',
                                color: '#fff',
                                zIndex: 3,
                            }}
                            onClick={handleNextImage}
                            disabled={currentImageIndex === gallery.items.length - 1}
                        >
                            <ArrowRightIcon />
                        </button>
                    </div>
                </DialogSurface>
            </Dialog>
            <div className={classes.root} ref={scrollableRef}>
                <div className={classes.wrapper}>
                    <div className="new-title" style={{ marginBottom: '16px' }}>
                        {isFavorite ? `My Favorites` : `My gallery`}
                    </div>
                    {Object.entries(groupedByDate).map(([date, items], index) => (
                        <div key={index} style={{ marginBottom: '16px' }}>
                            <p className={classes.sectionTitle}>{formatDateWithOrdinal(date)}</p>
                            <div className={classes.gridContainer}>
                                {items.map((item, idx) => (
                                    <div key={idx} className={classes.gridItem}>
                                        <Image
                                            onClick={() => {
                                                handleImage(item.thumbnail_url, item.download_url, item.originalIndex);
                                            }}
                                            src={item.gallery_thumbnail_url}
                                            alt={item.prompt_uuid}
                                            className={classes.image}
                                            shadow={true}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                    {loading && (
                        <div style={{ textAlign: 'center', padding: '20px' }}>
                            <Spinner label="Loading..." />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
